import React, { useEffect, useRef, useState } from "react";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import Axios from "axios";
import modifyDate from "../user_defined_script/modifyDate";
import ReCAPTCHA from "react-google-recaptcha";
function JobForm() {
  const navigate = useNavigate();
  document.title = "CMD | JOB"
  const focusElement = useRef(null);
  const key = "6LcxWuYpAAAAAPMM9ui-5LPUb9I4Vu_gen8G0AIZ";
  const [circleAnimation, setCircleAnimtion] = useState(
    <>
      <p>Submit</p>
      <i className="fa-solid fa-angles-right"></i>
    </>
  );
  const jobDataFromServer = useLocation();
  const [submitBtnDisAppear, setSubmitBtnDisAppear] = useState(true);
  const formData = jobDataFromServer.state !== null ? jobDataFromServer.state.data : {};
  const verifyData = () => {
    setSubmitBtnDisAppear(true);
  }
  useEffect(() => {
    if (jobDataFromServer.state !== null) {
      focusElement.current.focus()
    }
  }, [jobDataFromServer.state]);

  const checkImage = (event, size) => {
    let extension = event.target.value.slice(event.target.value.indexOf(".") + 1).toLowerCase();

    if (event.target.files[0] !== undefined && (event.target.files[0].size / 1024) > size) {
      document.querySelector(".job-submit-btn").disabled = true;
      // prompt(`Image is not less than ${size}kb`);
      event.target.nextElementSibling.firstElementChild.src = "";
      event.target.nextElementSibling.lastElementChild.firstElementChild.innerHTML = `Image is not less than ${size}kb`;
      return;
    }

    if ((extension !== undefined) && (extension !== "jpg" && extension !== "jpeg")) {
      document.querySelector(".job-submit-btn").disabled = true;
      // prompt(`Only accept jpg/jpeg image.`);
      event.target.nextElementSibling.firstElementChild.src = "";
      event.target.nextElementSibling.lastElementChild.firstElementChild.innerHTML = `Only accept jpg/jpeg image.`;
      return;
    }
    event.target.nextElementSibling.lastChild.firstElementChild.innerHTML = ``;
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      event.target.nextElementSibling.firstElementChild.src = reader.result;
    }

    event.target.previousElementSibling.firstElementChild.firstElementChild.innerHTML = "";
    document.querySelector(".job-submit-btn").disabled = false;
  }

  const object_key_change = (myObj, oldKey, newKey) => {
    if (myObj.hasOwnProperty(oldKey)) {
      myObj[newKey] = myObj[oldKey];
      delete myObj[oldKey];
    }
  }

  const create_SE_HS_Array = (arr, name) => {
    let returnData = arr.map((data, index) => {
      let innerData = { data: document.getElementsByName(`${name}${index}`)[0].value }
      object_key_change(innerData, "data", data.labels);
      return innerData;
    });
    return returnData;
  }

  const push_data = (arr, name1, name2, name3, name4) => {
    arr.push(document.getElementsByName(name1)[0].value);
    arr.push(document.getElementsByName(name2)[0].value);
    arr.push(document.getElementsByName(name3)[0].value);
    arr.push(name4);
    return arr;
  }

  const naviGatePage = () => {
    setTimeout(() => {
      navigate("/apply-for-job");
    }, 3000);
  }

  const submit_job_form = (event) => {
    event.preventDefault();
    document.querySelector(".job-submit-btn").type = "button";
    setCircleAnimtion(
      <div className="circleJobSubmitButton"></div>
    )
    const newFormData = new FormData();
    const jobForm = event.target;
    document.querySelector(".makeFormInvisible").style.display = "block";
    const full_name = jobForm.full_name.value;
    const post_name = formData.post_name;
    const post_prefix = formData.post_prefix;
    const guardian_name = jobForm.guardian_name.value;
    const present_address = jobForm.present_address.value;
    const permanent_address = jobForm.permanent_address.value;
    const m_number = jobForm.mobile_number.value;
    const email = jobForm.email_address.value;
    const DOB = jobForm.dob.value;
    const place_of_birth = jobForm.place_of_birth.value;
    const sex = jobForm.gender.value;
    const martial_status = jobForm.martial_status.value;

    let qualification1_data = [];
    if (formData.qualification1_status === "enabled") {
      qualification1_data = create_SE_HS_Array(formData.arr1.length !== 0 ? formData.arr1 : [], "se_subject");
      newFormData.append("image_file1", jobForm.image_file1.files[0]);
    }

    let qualification2_data = [];
    if (formData.qualification2_status === "enabled") {
      qualification2_data = create_SE_HS_Array(formData.arr2.length !== 0 ? formData.arr2 : [], "hs_subject");
      newFormData.append("image_file2", jobForm.image_file2.files[0]);
    }

    const qualification3_data = [];
    if (formData.qualification3_status === "enabled") {
      push_data(qualification3_data, "examination3_passed", "institution3", "passing3_year", formData.qualification3);
      newFormData.append("image_file3", jobForm.image_file3.files[0]);
    }

    const qualification4_data = [];
    if (formData.qualification4_status === "enabled") {
      push_data(qualification4_data, "examination4_passed", "institution4", "passing4_year", formData.qualification4);
      newFormData.append("image_file4", jobForm.image_file4.files[0]);
    }

    const qualification5_data = [];
    if (formData.qualification5_status !== "disabled") {
      push_data(qualification5_data, "examination5_passed", "institution5", "passing5_year", formData.qualification5);
      newFormData.append("image_file5", jobForm.image_file5.files[0]);
    }

    const qualification6_data = [];
    if (formData.qualification6_status !== "disabled") {
      push_data(qualification6_data, "examination6_passed", "institution6", "passing6_year", formData.qualification6);
      newFormData.append("image_file6", jobForm.image_file6.files[0]);
    }

    const qualification7_data = [];
    if (formData.qualification7_status !== "disabled") {
      push_data(qualification7_data, "examination7_passed", "institution7", "passing7_year", formData.qualification7);
      newFormData.append("image_file7", jobForm.image_file7.files[0]);
    }

    const qualification8_data = [];
    if (formData.qualification8_status !== "disabled") {
      push_data(qualification8_data, "examination8_passed", "institution8", "passing8_year", formData.qualification8);
      newFormData.append("image_file8", jobForm.image_file8.files[0]);
    }

    const qualification9_data = [];
    if (formData.qualification9_status !== "disabled") {
      push_data(qualification9_data, "examination9_passed", "institution9", "passing9_year", formData.qualification9);
      newFormData.append("image_file9", jobForm.image_file9.files[0]);
    }

    const qualification10_data = [];
    if (formData.qualification10_status !== "disabled") {
      push_data(qualification10_data, "examination10_passed", "institution10", "passing10_year", formData.qualification10);
      newFormData.append("image_file10", jobForm.image_file10.files[0]);
    }

    const activities = jobForm.activities.value;

    const english = jobForm.english.value;
    const hindi = jobForm.hindi.value;
    const bengali = jobForm.bengali.value;

    const organization1_address = jobForm.organization1_address.value;
    const organization1_joining = jobForm.organization1_joining.value;
    const organization1_working_status = jobForm.organization1_working_status.value;
    const organization1_job_role = jobForm.organization1_job_role.value;
    const organization1_salary = jobForm.organization1_salary.value;
    newFormData.append("orga1_image_file", jobForm.salary_image_file1.files[0])

    const organization2_address = jobForm.organization2_address.value;
    const organization2_joining = jobForm.organization2_joining.value;
    const organization2_working_status = jobForm.organization2_working_status.value;
    const organization2_job_role = jobForm.organization2_job_role.value;
    const organization2_salary = jobForm.organization2_salary.value;
    newFormData.append("orga2_image_file", jobForm.salary_image_file2.files[0])

    newFormData.append("applicant_image_file", jobForm.applicant_image_file.files[0]);
    newFormData.append("resume_image_file", jobForm.resume_image_file.files[0]);
    newFormData.append("signature_image_file", jobForm.signature_image_file.files[0]);

    const current_date = jobForm.current_date.value;
    const place = jobForm.place.value;
    const yourself = jobForm.about_yourself.value;
    const expected_salary = jobForm.expected_salary.value;

    Axios.get(`https://career.cmdncsm.in/valid-user?user_email=${email}&post_prefix=${post_prefix}`).then((response) => {
      if (response.data) {
        fetch('https://api.ipify.org?format=json')
          .then(response => response.json())
          .then((data) => {
            Axios.post("https://career.cmdncsm.in/employee-form-images", newFormData).then((response1) => {
              if (response1.data) {
                const userIp = data.ip;
                Axios.post("https://career.cmdncsm.in/employee-form-data", {
                  full_name, post_name, post_prefix, guardian_name, present_address, permanent_address, m_number, email, DOB, place_of_birth, sex, martial_status, qualification1_data, qualification2_data, qualification3_data, qualification4_data, qualification5_data, qualification6_data, qualification7_data, qualification8_data, qualification9_data, qualification10_data, activities, english, hindi, bengali, organization1_address, organization1_joining, organization1_working_status, organization1_job_role, organization1_salary, organization2_address, organization2_joining, organization2_working_status, organization2_job_role, organization2_salary, current_date, place, yourself, expected_salary, userIp
                }).then((response2) => {
                  if (response2.data) {
                    setCircleAnimtion("Your form is successfully submitted.");
                    naviGatePage();
                  } else {
                    setCircleAnimtion("Your form was not successfully submitted.");
                    naviGatePage();
                  }
                }).catch((response2) => {
                  console.log("in catch", response2.data)
                })
              }
            }).catch((response) => {
              console.log("outter", response.data);
            });
          })
          .catch(error => console.error('Error fetching IP address:', error));
      } else {
        setCircleAnimtion("Your form already successfully submitted.");
        naviGatePage();
      }
    })
  }

  return (
    jobDataFromServer.state !== null ? <div className="job-form-container">
      <div className="jobFormContainer">
        <div className="formHeading">
          <p>{formData.post_name}</p>
        </div>
        <div className="important-info">
          <h2>Important Dates</h2>
          <ul>
            <li>
              <i className="fa-regular fa-circle-dot"></i>
              <p>Date of Written Test: {modifyDate(formData.date_written_test)}</p>
            </li>
            <li>
              <i className="fa-regular fa-circle-dot"></i>
              <p>Date of Practical Test: {modifyDate(formData.date_practical_test)}</p>
            </li>
            <li>
              <i className="fa-regular fa-circle-dot"></i>
              <p>Date of Interview Test: {modifyDate(formData.date_interview_test)}</p>
            </li>
            <li>
              <i className="fa-regular fa-circle-dot"></i>
              <p>Last Date of Submissison: {modifyDate(formData.date_submission)}</p>
            </li>
          </ul>
          <h2>Image files should be like this...</h2>
          <ul>
            <li>
              <i className="fa-regular fa-circle-dot"></i>
              <p>Please upload image with given rulse!</p>
            </li>
            <li>
              <i className="fa-regular fa-circle-dot"></i>
              <p>Each file will have a different name (name.jpg/resume.pdf)!</p>
            </li>
          </ul>
        </div>
        <form className="jobForm" encType="multipart/form-data" onSubmit={submit_job_form} >
          <div className="makeFormInvisible"></div>
          <div className="input-row personal-details">
            <div className="input-heading-container input-label">
              <p className="bullet"><i className="fa-regular fa-circle-dot"></i></p>
              <p className="input-heading">Personal Details</p>
            </div>
            <div className="input-data-container">
              <div className="input-data">
                <label>Full Name</label>
                <input className="job-input-field" pattern="^[A-Z][a-z]*([ ][A-Z][a-z]*)*$" type="text" maxLength={40} name="full_name" ref={focusElement} required />
              </div>
              <div className="input-data">
                <label>{formData.guardian_name}</label>
                <input className="job-input-field" pattern="^[A-Z][a-z]*([ ][A-Z][a-z]*)*$" type="text" maxLength={40} name="guardian_name" required />
              </div>
            </div>
          </div>
          <div className="input-row personal-details">
            <div className="input-heading-container input-label">
              <p className="bullet"><i className="fa-regular fa-circle-dot"></i></p>
              <p className="input-heading">Contact Details</p>
            </div>
            <div className="input-data-container">
              <div className="input-data">
                <label>Present Address</label>
                <input className="job-input-field" type="text" maxLength={100} name="present_address" required />
              </div>
              <div className="input-data">
                <label>Permanent Address</label>
                <input className="job-input-field" type="text" maxLength={100} name="permanent_address" required />
              </div>
              <div className="input-data">
                <label>Mobile Number (+91)</label>
                <input className="job-input-field" type="text" pattern="^\+91\d{10}$" maxLength={13} name="mobile_number" required />
              </div>
              <div className="input-data">
                <label>Email Address</label>
                <input className="job-input-field" type="email" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" maxLength={40} name="email_address" required />
              </div>
            </div>
          </div>
          <div className="input-row personal-details">
            <div className="input-heading-container input-label">
              <p className="bullet"><i className="fa-regular fa-circle-dot"></i></p>
              <p className="input-heading">Addtional Details</p>
            </div>
            <div className="input-data-container">
              <div className="input-data">
                <label>Date of Birth</label>
                <input className="job-input-field" type="date" name="dob" required />
              </div>
              <div className="input-data">
                <label>Place of Birth</label>
                <input className="job-input-field" type="text" name="place_of_birth" maxLength={30} required />
              </div>
              <div className="input-data">
                <label>Gender</label>
                <select name="gender" className="select-data" required>
                  <option value="">---Choose an option---</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="input-data">
                <label>Marital Status</label>
                <select name="martial_status" className="select-data" required>
                  <option value="">---Choose an option---</option>
                  <option value="Married">Married</option>
                  <option value="Unmarried">Unmarried</option>
                </select>
              </div>
            </div>
          </div>
          <div className="input-row personal-details education-area">
            <div className="input-heading-container input-label">
              <p className="bullet"><i className="fa-regular fa-circle-dot"></i></p>
              <p className="input-heading">Education Qualification</p>
            </div>
            {formData.qualification1 !== "" && formData.qualification1_status !== "disabled" ? <div className="education-data">
              <div className="input-row personal-details">
                <div className="input-heading-container input-label secondary-label">
                  <p className="bullet">&bull;</p>
                  <p className="input-heading">Secondary Education <span >(Required)</span> </p>
                </div>
                <div className="input-data-container">
                  {
                    formData.arr1 && formData.arr1.map((data, index) => {
                      return (
                        <div className="input-data" key={index}>
                          <label>{data.labels}</label>
                          <input className="job-input-field" type={`${data.type}`} step="0.01" name={`se_subject${index}`} required />
                        </div>
                      )
                    })
                  }
                  <div className="input-data">
                    <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only<span></span> </span></label>
                    <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file1" onChange={(event) => checkImage(event, 250)} required />
                    <div className="display_image">
                      <img src="" alt="images" />
                      <div className="show-image-err-msg">
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> : null}
            {formData.qualification2 !== "" && formData.qualification2_status !== "disabled" ? <div className="education-data">
              <div className="input-row personal-details">
                <div className="input-heading-container input-label secondary-label">
                  <p className="bullet">&bull;</p>
                  <p className="input-heading">Higher Secondary Education <span >(Required)</span> </p>
                </div>
                <div className="input-data-container">
                  {
                    formData.arr2 && formData.arr2.map((data, index) => {
                      return (
                        <div className="input-data" key={index}>
                          <label>{data.labels}</label>
                          <input className="job-input-field" type={`${data.type}`} step="0.01" name={`hs_subject${index}`} required />
                        </div>
                      )
                    })
                  }
                  <div className="input-data">
                    <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only<span></span> </span></label>
                    <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file2" onChange={(event) => checkImage(event, 250)} required />
                    <div className="display_image">
                      <img src="" alt="images" />
                      <div className="show-image-err-msg">
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> : null}
            {formData.qualification3 !== "" && formData.qualification3_status !== "disabled" ?
              <div className="education-data">
                <div className="input-row personal-details">
                  <div className="input-heading-container input-label secondary-label">
                    <p className="bullet">&bull;</p>
                    <p className="input-heading">{formData.qualification3} <span >{formData.qualification3_required === "yes" ? "(Required)" : "(Optional)"}</span> </p>
                  </div>
                  {
                    formData.qualification3_required === "yes" ? <div className="input-data-container">
                      <div className="input-data">
                        <label>Examination Passed:</label>
                        <input className="job-input-field" type="text" name="examination3_passed" required />
                      </div>
                      <div className="input-data">
                        <label>Name of University / Institution:</label>
                        <input className="job-input-field" type="text" name="institution3" required />
                      </div>
                      <div className="input-data">
                        <label>Year of Passing:</label>
                        <input type="number" step="0.01" className="job-input-field" name="passing3_year" required />
                      </div>
                      <div className="input-data">
                        <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only<span></span> </span></label>
                        <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file3" onChange={(event) => checkImage(event, 250)} required />
                        <div className="display_image">
                          <img src="" alt="images" />
                          <div className="show-image-err-msg">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div> : <div className="input-data-container">
                      <div className="input-data">
                        <label>Examination Passed:</label>
                        <input className="job-input-field" type="text" name="examination3_passed" />
                      </div>
                      <div className="input-data">
                        <label>Name of University / Institution:</label>
                        <input className="job-input-field" type="text" name="institution3" />
                      </div>
                      <div className="input-data">
                        <label>Year of Passing:</label>
                        <input type="number" step="0.01" className="job-input-field" name="passing3_year" />
                      </div>
                      <div className="input-data">
                        <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only <span></span> </span></label>
                        <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file3" onChange={(event) => checkImage(event, 250)} />
                        <div className="display_image">
                          <img src="" alt="images" />
                          <div className="show-image-err-msg">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                </div>
              </div> : null}
            {formData.qualification4 !== "" && formData.qualification4_status !== "disabled" ?
              <div className="education-data">
                <div className="input-row personal-details">
                  <div className="input-heading-container input-label secondary-label">
                    <p className="bullet">&bull;</p>
                    <p className="input-heading">{formData.qualification4} <span >{formData.qualification4_required === "yes" ? "(Required)" : "(Optional)"}</span> </p>
                  </div>
                  {
                    formData.qualification4_required === "yes" ? <div className="input-data-container">
                      <div className="input-data">
                        <label>Examination Passed:</label>
                        <input className="job-input-field" type="text" name="examination4_passed" required />
                      </div>
                      <div className="input-data">
                        <label>Name of University / Institution:</label>
                        <input className="job-input-field" type="text" name="institution4" required />
                      </div>
                      <div className="input-data">
                        <label>Year of Passing:</label>
                        <input type="number" step="0.01" className="job-input-field" name="passing4_year" required />
                      </div>
                      <div className="input-data">
                        <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only <span></span> </span></label>
                        <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file4" onChange={(event) => checkImage(event, 250)} required />
                        <div className="display_image">
                          <img src="" alt="images" />
                          <div className="show-image-err-msg">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div> : <div className="input-data-container">
                      <div className="input-data">
                        <label>Examination Passed:</label>
                        <input className="job-input-field" type="text" name="examination4_passed" />
                      </div>
                      <div className="input-data">
                        <label>Name of University / Institution:</label>
                        <input className="job-input-field" type="text" name="institution4" />
                      </div>
                      <div className="input-data">
                        <label>Year of Passing:</label>
                        <input type="number" step="0.01" className="job-input-field" name="passing4_year" />
                      </div>
                      <div className="input-data">
                        <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only <span></span> </span></label>
                        <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file4" onChange={(event) => checkImage(event, 250)} />
                        <div className="display_image">
                          <img src="" alt="images" />
                          <div className="show-image-err-msg">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div> : null}
            {formData.qualification5 !== "" && formData.qualification5_status !== "disabled" ?
              <div className="education-data">
                <div className="input-row personal-details">
                  <div className="input-heading-container input-label secondary-label">
                    <p className="bullet">&bull;</p>
                    <p className="input-heading">{formData.qualification5} <span >{formData.qualification5_required === "yes" ? "(Required)" : "(Optional)"}</span> </p>
                  </div>
                  {
                    formData.qualification5_required === "yes" ? <div className="input-data-container">
                      <div className="input-data">
                        <label>Examination Passed:</label>
                        <input className="job-input-field" type="text" name="examination5_passed" required />
                      </div>
                      <div className="input-data">
                        <label>Name of University / Institution:</label>
                        <input className="job-input-field" type="text" name="institution5" required />
                      </div>
                      <div className="input-data">
                        <label>Year of Passing:</label>
                        <input type="number" step="0.01" className="job-input-field" name="passing5_year" required />
                      </div>
                      <div className="input-data">
                        <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only<span></span> </span></label>
                        <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file5" onChange={(event) => checkImage(event, 250)} required />
                        <div className="display_image">
                          <img src="" alt="images" />
                          <div className="show-image-err-msg">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div> : <div className="input-data-container">
                      <div className="input-data">
                        <label>Examination Passed:</label>
                        <input className="job-input-field" type="text" name="examination5_passed" />
                      </div>
                      <div className="input-data">
                        <label>Name of University / Institution:</label>
                        <input className="job-input-field" type="text" name="institution5" />
                      </div>
                      <div className="input-data">
                        <label>Year of Passing:</label>
                        <input type="number" step="0.01" className="job-input-field" name="passing5_year" />
                      </div>
                      <div className="input-data">
                        <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only<span></span> </span></label>
                        <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file5" onChange={(event) => checkImage(event, 250)} />
                        <div className="display_image">
                          <img src="" alt="images" />
                          <div className="show-image-err-msg">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div> : null}
            {formData.qualification6 !== "" && formData.qualification6_status !== "disabled" ?
              <div className="education-data">
                <div className="input-row personal-details">
                  <div className="input-heading-container input-label secondary-label">
                    <p className="bullet">&bull;</p>
                    <p className="input-heading">{formData.qualification6} <span >{formData.qualification6_required === "yes" ? "(Required)" : "(Optional)"}</span> </p>
                  </div>
                  {
                    formData.qualification6_required === "yes" ? <div className="input-data-container">
                      <div className="input-data">
                        <label>Examination Passed:</label>
                        <input className="job-input-field" type="text" name="examination6_passed" required />
                      </div>
                      <div className="input-data">
                        <label>Name of University / Institution:</label>
                        <input className="job-input-field" type="text" name="institution6" required />
                      </div>
                      <div className="input-data">
                        <label>Year of Passing:</label>
                        <input type="number" step="0.01" className="job-input-field" name="passing6_year" required />
                      </div>
                      <div className="input-data">
                        <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only<span></span> </span></label>
                        <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file6" onChange={(event) => checkImage(event, 250)} required />
                        <div className="display_image">
                          <img src="" alt="images" />
                          <div className="show-image-err-msg">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div> : <div className="input-data-container">
                      <div className="input-data">
                        <label>Examination Passed:</label>
                        <input className="job-input-field" type="text" name="examination6_passed" />
                      </div>
                      <div className="input-data">
                        <label>Name of University / Institution:</label>
                        <input className="job-input-field" type="text" name="institution6" />
                      </div>
                      <div className="input-data">
                        <label>Year of Passing:</label>
                        <input type="number" step="0.01" className="job-input-field" name="passing6_year" />
                      </div>
                      <div className="input-data">
                        <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only<span></span> </span></label>
                        <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file6" onChange={(event) => checkImage(event, 250)} />
                        <div className="display_image">
                          <img src="" alt="images" />
                          <div className="show-image-err-msg">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div> : null}
            {formData.qualification7 !== "" && formData.qualification7_status !== "disabled" ?
              <div className="education-data">
                <div className="input-row personal-details">
                  <div className="input-heading-container input-label secondary-label">
                    <p className="bullet">&bull;</p>
                    <p className="input-heading">{formData.qualification7} <span >{formData.qualification7_required === "yes" ? "(Required)" : "(Optional)"}</span> </p>
                  </div>
                  {
                    formData.qualification7_required === "yes" ? <div className="input-data-container">
                      <div className="input-data">
                        <label>Examination Passed:</label>
                        <input className="job-input-field" type="text" name="examination7_passed" required />
                      </div>
                      <div className="input-data">
                        <label>Name of University / Institution:</label>
                        <input className="job-input-field" type="text" name="institution7" required />
                      </div>
                      <div className="input-data">
                        <label>Year of Passing:</label>
                        <input type="number" step="0.01" className="job-input-field" name="passing7_year" required />
                      </div>
                      <div className="input-data">
                        <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only<span></span> </span></label>
                        <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file7" onChange={(event) => checkImage(event, 250)} required />
                        <div className="display_image">
                          <img src="" alt="images" />
                          <div className="show-image-err-msg">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div> : <div className="input-data-container">
                      <div className="input-data">
                        <label>Examination Passed:</label>
                        <input className="job-input-field" type="text" name="examination7_passed" />
                      </div>
                      <div className="input-data">
                        <label>Name of University / Institution:</label>
                        <input className="job-input-field" type="text" name="institution7" />
                      </div>
                      <div className="input-data">
                        <label>Year of Passing:</label>
                        <input type="number" step="0.01" className="job-input-field" name="passing7_year" />
                      </div>
                      <div className="input-data">
                        <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only<span></span> </span></label>
                        <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file7" onChange={(event) => checkImage(event, 250)} />
                        <div className="display_image">
                          <img src="" alt="images" />
                          <div className="show-image-err-msg">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div> : null}
            {formData.qualification8 !== "" && formData.qualification8_status !== "disabled" ?
              <div className="education-data">
                <div className="input-row personal-details">
                  <div className="input-heading-container input-label secondary-label">
                    <p className="bullet">&bull;</p>
                    <p className="input-heading">{formData.qualification8} <span >{formData.qualification8_required === "yes" ? "(Required)" : "(Optional)"}</span> </p>
                  </div>
                  {
                    formData.qualification8_required === "yes" ? <div className="input-data-container">
                      <div className="input-data">
                        <label>Examination Passed:</label>
                        <input className="job-input-field" type="text" name="examination8_passed" required />
                      </div>
                      <div className="input-data">
                        <label>Name of University / Institution:</label>
                        <input className="job-input-field" type="text" name="institution8" required />
                      </div>
                      <div className="input-data">
                        <label>Year of Passing:</label>
                        <input type="number" step="0.01" className="job-input-field" name="passing8_year" required />
                      </div>
                      <div className="input-data">
                        <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only<span></span> </span></label>
                        <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file8" onChange={(event) => checkImage(event, 250)} required />
                        <div className="display_image">
                          <img src="" alt="images" />
                          <div className="show-image-err-msg">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div> : <div className="input-data-container">
                      <div className="input-data">
                        <label>Examination Passed:</label>
                        <input className="job-input-field" type="text" name="examination8_passed" />
                      </div>
                      <div className="input-data">
                        <label>Name of University / Institution:</label>
                        <input className="job-input-field" type="text" name="institution8" />
                      </div>
                      <div className="input-data">
                        <label>Year of Passing:</label>
                        <input type="number" step="0.01" className="job-input-field" name="passing8_year" />
                      </div>
                      <div className="input-data">
                        <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only<span></span> </span></label>
                        <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file8" onChange={(event) => checkImage(event, 250)} />
                        <div className="display_image">
                          <img src="" alt="images" />
                          <div className="show-image-err-msg">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div> : null}
            {formData.qualification9 !== "" && formData.qualification9_status !== "disabled" ?
              <div className="education-data">
                <div className="input-row personal-details">
                  <div className="input-heading-container input-label secondary-label">
                    <p className="bullet">&bull;</p>
                    <p className="input-heading">{formData.qualification9} <span >{formData.qualification9_required === "yes" ? "(Required)" : "(Optional)"}</span> </p>
                  </div>
                  {
                    formData.qualification9_required === "yes" ? <div className="input-data-container">
                      <div className="input-data">
                        <label>Examination Passed:</label>
                        <input className="job-input-field" type="text" name="examination9_passed" required />
                      </div>
                      <div className="input-data">
                        <label>Name of University / Institution:</label>
                        <input className="job-input-field" type="text" name="institution9" required />
                      </div>
                      <div className="input-data">
                        <label>Year of Passing:</label>
                        <input type="number" step="0.01" className="job-input-field" name="passing9_year" required />
                      </div>
                      <div className="input-data">
                        <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only<span></span> </span></label>
                        <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file9" onChange={(event) => checkImage(event, 250)} required />
                        <div className="display_image">
                          <img src="" alt="images" />
                          <div className="show-image-err-msg">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div> : <div className="input-data-container">
                      <div className="input-data">
                        <label>Examination Passed:</label>
                        <input className="job-input-field" type="text" name="examination9_passed" />
                      </div>
                      <div className="input-data">
                        <label>Name of University / Institution:</label>
                        <input className="job-input-field" type="text" name="institution9" />
                      </div>
                      <div className="input-data">
                        <label>Year of Passing:</label>
                        <input type="number" step="0.01" className="job-input-field" name="passing9_year" />
                      </div>
                      <div className="input-data">
                        <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only<span></span> </span></label>
                        <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file9" onChange={(event) => checkImage(event, 250)} />
                        <div className="display_image">
                          <img src="" alt="images" />
                          <div className="show-image-err-msg">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div> : null}
            {formData.qualification10 !== "" && formData.qualification10_status !== "disabled" ?
              <div className="education-data">
                <div className="input-row personal-details">
                  <div className="input-heading-container input-label secondary-label">
                    <p className="bullet">&bull;</p>
                    <p className="input-heading">{formData.qualification10} <span >{formData.qualification10_required === "yes" ? "(Required)" : "(Optional)"}</span> </p>
                  </div>
                  {
                    formData.qualification10_required === "yes" ? <div className="input-data-container">
                      <div className="input-data">
                        <label>Examination Passed:</label>
                        <input className="job-input-field" type="text" name="examination10_passed" required />
                      </div>
                      <div className="input-data">
                        <label>Name of University / Institution:</label>
                        <input className="job-input-field" type="text" name="institution10" required />
                      </div>
                      <div className="input-data">
                        <label>Year of Passing:</label>
                        <input type="number" step="0.01" className="job-input-field" name="passing10_year" required />
                      </div>
                      <div className="input-data">
                        <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only<span></span> </span></label>
                        <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file10" onChange={(event) => checkImage(event, 250)} required />
                        <div className="display_image">
                          <img src="" alt="images" />
                          <div className="show-image-err-msg">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div> : <div className="input-data-container">
                      <div className="input-data">
                        <label>Examination Passed:</label>
                        <input className="job-input-field" type="text" name="examination10_passed" />
                      </div>
                      <div className="input-data">
                        <label>Name of University / Institution:</label>
                        <input className="job-input-field" type="text" name="institution10" />
                      </div>
                      <div className="input-data">
                        <label>Year of Passing:</label>
                        <input type="number" step="0.01" className="job-input-field" name="passing10_year" />
                      </div>
                      <div className="input-data">
                        <label>Document Attached <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only<span></span> </span></label>
                        <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="image_file10" onChange={(event) => checkImage(event, 250)} />
                        <div className="display_image">
                          <img src="" alt="images" />
                          <div className="show-image-err-msg">
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div> : null}
          </div>
          <div className="input-row personal-details">
            <div className="input-heading-container input-label">
              <p className="bullet"><i className="fa-regular fa-circle-dot"></i></p>
              <p className="input-heading">Extracurricular Activities:</p>
            </div>
            <div className="input-data-container">
              <div className="input-data">
                <label>Activities</label>
                <textarea name="activities" cols="30" rows="5" required></textarea>
              </div>
            </div>
          </div>
          <div className="input-row personal-details">
            <div className="input-heading-container input-label">
              <p className="bullet"><i className="fa-regular fa-circle-dot"></i></p>
              <p className="input-heading">Language Known</p>
            </div>
            <div className="input-data-container">
              <div className="input-data">
                <label>Bengali</label>
                <select name="bengali" className="select-data" required>
                  <option value="">---Choose an option---</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="input-data">
                <label>English</label>
                <select name="english" className="select-data" required>
                  <option value="">---Choose an option---</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="input-data">
                <label>Hindi</label>
                <select name="hindi" className="select-data" required>
                  <option value="">---Choose an option---</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
          </div>
          <div className="input-row personal-details education-area">
            <div className="input-heading-container input-label">
              <p className="bullet"><i className="fa-regular fa-circle-dot"></i></p>
              <p className="input-heading">Working Experience <span style={{ fontWeight: "400", fontSize: "1.3rem" }}><i className="fa-solid fa-circle-info"></i>If you are fresher then you can skip the working experience step organization 1, organization 2!</span></p>
            </div>
            <div className="education-data">
              <div className="input-row personal-details">
                <div className="input-heading-container input-label secondary-label">
                  <p className="bullet">&bull;</p>
                  <p className="input-heading">Organization 1</p>
                </div>
                <div className="input-data-container">
                  <div className="input-data">
                    <label>Name and Address of Organization:</label>
                    <input className="job-input-field" type="text" name="organization1_address" />
                  </div>
                  <div className="input-data">
                    <label>Date of Joining(DD-MM-YYYY):</label>
                    <input className="job-input-field" type="date" name="organization1_joining" />
                  </div>
                  <div className="input-data">
                    <label>Working Status: </label>
                    <select name="organization1_working_status" className="select-data">
                      <option value="">---Choose an option---</option>
                      <option value="Leaved">Leaved</option>
                      <option value="Still Working">Still Working</option>
                    </select>
                  </div>
                  <div className="input-data">
                    <label>Nature of Work & Responsibilities:</label>
                    <input className="job-input-field" type="text" name="organization1_job_role" />
                  </div>
                  <div className="input-data">
                    <label>Salary (INR):</label>
                    <input className="job-input-field" type="text" name="organization1_salary" />
                  </div>
                  <div className="input-data">
                    <label>Salary Document <span ><i className="fa-solid fa-circle-info"></i>color photo under 150kb JPEG/JPG only<p></p> </span></label>
                    <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="salary_image_file1" onChange={(event) => checkImage(event, 150)} />
                    <div className="display_image">
                      <img src="" alt="images" />
                      <div className="show-image-err-msg">
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-row personal-details">
                <div className="input-heading-container input-label secondary-label">
                  <p className="bullet">&bull;</p>
                  <p className="input-heading">Organization 2</p>
                </div>
                <div className="input-data-container">
                  <div className="input-data">
                    <label>Name and Address of Organization:</label>
                    <input className="job-input-field" type="text" name="organization2_address" />
                  </div>
                  <div className="input-data">
                    <label>Date of Joining(DD-MM-YYYY):</label>
                    <input className="job-input-field" type="date" name="organization2_joining" />
                  </div>
                  <div className="input-data">
                    <label>Working Status: </label>
                    <select name="organization2_working_status" className="select-data">
                      <option value="">---Choose an option---</option>
                      <option value="Leaved">Leaved</option>
                      <option value="Still Working">Still Working</option>
                    </select>
                  </div>
                  <div className="input-data">
                    <label>Nature of Work & Responsibilities:</label>
                    <input className="job-input-field" type="text" name="organization2_job_role" />
                  </div>
                  <div className="input-data">
                    <label>Salary (INR):</label>
                    <input className="job-input-field" type="number" min="0" step="0.01" name="organization2_salary" />
                  </div>
                  <div className="input-data">
                    <label>Salary Document <span ><i className="fa-solid fa-circle-info"></i>color photo under 150kb JPEG/JPG only<p></p> </span></label>
                    <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="salary_image_file2" onChange={(event) => checkImage(event, 150)} />
                    <div className="display_image">
                      <img src="" alt="images" />
                      <div className="show-image-err-msg">
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="input-row personal-details">
            <div className="input-heading-container input-label">
              <p className="bullet"><i className="fa-regular fa-circle-dot"></i></p>
              <p className="input-heading">Important Documents: </p>
            </div>
            <div className="input-data-container">
              <div className="input-data">
                <label>Current Date: </label>
                <input className="job-input-field" type="date" name="current_date" required />
              </div>
              <div className="input-data">
                <label>Place: </label>
                <input className="job-input-field" type="text" maxLength={40} name="place" required />
              </div>
              <div className="input-data">
                <label>Your Photo <span ><i className="fa-solid fa-circle-info"></i>color photo under 250kb JPEG/JPG only<span></span> </span></label>
                <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="applicant_image_file" required onChange={(event) => checkImage(event, 250)} />
                <div className="display_image">
                  <img src="" alt="images" />
                  <div className="show-image-err-msg">
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="input-data">
                <label>Resume <span ><i className="fa-solid fa-circle-info"></i>pdf only<span></span> </span></label>
                <input className="job-input-field" accept=".pdf, .PDF" type="file" name="resume_image_file" required />
              </div>
              <div className="input-data">
                <label>Signature <span ><i className="fa-solid fa-circle-info"></i>color photo under 50kb JPEG/JPG only<span></span> </span></label>
                <input className="job-input-field" accept=".jpg, .jpeg" type="file" name="signature_image_file" onChange={(event) => checkImage(event, 50)} required />
                <div className="display_image signature_image">
                  <img src="" alt="images" />
                  <div className="show-image-err-msg">
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="input-data">
                <label>Write-up on yourself and your career:</label>
                <textarea name="about_yourself" cols="30" rows="5" required></textarea>
              </div>
              <div className="input-data">
                <label>Expected Salary:</label>
                <input type="text" className="job-input-field" name="expected_salary" min="0" step="0.01" />
              </div>
            </div>
          </div>
          <div className="captcha-container">
            <ReCAPTCHA
              sitekey={key}
              onChange={verifyData}
            />
            {submitBtnDisAppear && <div className="submit-btn-container">
              <button type="submit" className="job-submit-btn">
                {circleAnimation}
              </button>
            </div>}
          </div>
        </form>
      </div>
    </div> : < Navigate to="*" />
  )
}

export default React.memo(JobForm);