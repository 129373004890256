import React, {
    useEffect,
    useContext,
    useState
} from "react";
import {
    Link
} from "react-router-dom";
import LoadingAnimation from "../components/LoadingAnimation";
import searchData from "../user_defined_script/searchData";
import DataNotFoundMsg from "../components/DataNotFoundMsg";
import Axios from "axios";
import { UserContext } from "../App";
import sliceText from "../user_defined_script/sliceText";
function PhotoGallery() {
    document.title = "CMD | Photo Gallery";
    const preDefinedFunc = useContext(UserContext);
    const [photoGalleryData, setPhotoGalleryData] = useState([]);
    const [bannerData, setBannerData] = useState([]);
    const [allPhotoGallery, setAllPhotoGallery] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        let photoGalleryBannerData = [];
        let totalPhotoGalleryData = [];
        try {
            let PhotoGalleryBanner = await Axios.get("https://admin.cmdncsm.in/banner-data?identifier=Photo gallery");
            let PhotoGalleryData = await Axios.get("https://admin.cmdncsm.in/projects");
            let PhotoGalleryInsideData = await Axios.get("https://admin.cmdncsm.in/projects-inside-data");
            photoGalleryBannerData = PhotoGalleryBanner.data;
            totalPhotoGalleryData = PhotoGalleryData.data.map((data) => {
                let addArr = [];
                return ({ ...data, addArr });
            })

            for (let i = 0; i < totalPhotoGalleryData.length; i++) {
                for (let j = 0; j < PhotoGalleryInsideData.data.length; j++) {
                    if (totalPhotoGalleryData[i].id === PhotoGalleryInsideData.data[j].image_key) {
                        totalPhotoGalleryData[i].addArr.push(PhotoGalleryInsideData.data[j]);
                    }
                }
            }
            return { photoGalleryBannerData, totalPhotoGalleryData }
        } catch (error) {
            return { error: true, photoGalleryBannerData, totalPhotoGalleryData }
        }
    }

    useEffect(() => {
        document.querySelector(".main_container").style.overflowY = "hidden";
        const getData = async () => {
            let data = await fetchData();
            if (data.error) {
                setBannerData([])
                setPhotoGalleryData([]);
                setAllPhotoGallery([]);
            } else {
                setBannerData(data.photoGalleryBannerData)
                setPhotoGalleryData(data.totalPhotoGalleryData);
                setAllPhotoGallery(data.totalPhotoGalleryData);
            }
            setLoading(false);
            document.querySelector(".main_container").style.overflowY = "auto";
        }
        getData();
    }, []);

    useEffect(() => {
        const gridItem = document.querySelectorAll(".work-display-item");
        const observeNewDivFunc = (element, value, thresholdValue) => {
            const observer = new IntersectionObserver(preDefinedFunc[1](value), { ...preDefinedFunc[0], threshold: thresholdValue });
            observer.observe(element);
        }
        gridItem.forEach((item) => {
            observeNewDivFunc(item, "textTransformAnimation", .1);
        });
    });

    return (
        <div className="project_page position-relative work-display display-margin-top-5rem">
            {loading ? <LoadingAnimation /> : null}
            <div className="project_page-header work-display-header">
                {
                    bannerData.length !== 0 ? bannerData[0].banner_title !== "" || bannerData[0].image_file !== "" ? <>
                        <picture>
                            <source srcSet={`https://admin.cmdncsm.in/public/banners_data/${bannerData[0].image_file1}`} media='(max-width: 500px)' />
                            <img src={`https://admin.cmdncsm.in/public/banners_data/${bannerData[0].image_file}`} alt="cmd_header_image" className="tender_header-img work-display-header-img" />
                        </picture>
                        <div className="gradient gradient-more"></div>
                        <div className="tender_header-text work-display-header-text">
                            <div className="tender_header-heading work-display_header-heading letter-spacing-2px">
                                {bannerData[0].banner_title}
                            </div>
                            {
                                bannerData[0].banner_sub_title !== "" ? <div className="about_header-sub-heading1 work-display_header-sub-heading1 letter-spacing-1px">{bannerData[0].banner_sub_title}</div> : null
                            }
                            {
                                bannerData[0].alter_text !== "" ? <div className="stakeholders_header-sub-heading2 work-display_header-sub-heading2">{bannerData[0].alter_text}</div> : null
                            }
                        </div>
                    </> : <DataNotFoundMsg /> : null
                }
            </div>
            <div className="searchContainer padding-6rem tender-padding padding-10rem">
                <div className="serachBox">
                    <input type="text" placeholder="Search:" onChange={(e) => { setPhotoGalleryData(searchData(e.target.value, allPhotoGallery.length !== 0 ? allPhotoGallery : [])) }} id="serach" />
                    <div className="searchIcon">
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                </div>
            </div>
            <div className="project_page-content work-display-content less-margin-for-mobile">
                <div className="project-item-grid work-display-item-grid">
                    {
                        photoGalleryData.length !== 0 ? photoGalleryData.map((data, index) => {
                            return (
                                <Link className="product-item work-display-item" to="/details" key={index} state={{ data: data, heading1: "Photo Gallery", heading2: "", sourcePath: "Project_images" }}>
                                    <div className="product-item_img work-display-item_img">
                                        <picture>
                                            <source srcSet={`https://admin.cmdncsm.in/public/Project_images/${data.banner_image_primary1}`} media='(max-width: 500px)' />
                                            <img src={`https://admin.cmdncsm.in/public/Project_images/${data.banner_image_primary}`} alt="cmd_header_image" />
                                        </picture>
                                    </div>
                                    <div className="product-item-text work-display-item-text">
                                        <h1 className="letter-spacing-1px">{data.title}</h1>
                                        <p dangerouslySetInnerHTML={{ __html: sliceText(data.sort_description) }}></p>
                                    </div>
                                </Link>
                            )
                        }) : <DataNotFoundMsg />
                    }
                </div>
            </div>
        </div>
    )
}

export default React.memo(PhotoGallery);