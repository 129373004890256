import './App.css';
// import "./style_folders/mediaQuery.css"
import React, {
  useEffect,
  useRef,
  createContext,
  useState,
} from 'react';
import Axios from 'axios';
import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

//! import pages
import Home from "./pages/Home.js";
import Product from './pages/Product.js';
import Project from './pages/Project.js';
import Dpr from './pages/Dpr.js';
import PhotoGallery from './pages/PhotoGallery.js';
import Tender from './pages/Tender.js';
import TendersArchive from './pages/TenderArchived.js';
import Career from './pages/Career.js';
import ApplyForJob from './pages/ApplyForJob.js';
import JobForm from './pages/JobForm.js';
import AboutUs from './pages/AboutUs.js';
import MD from './pages/MD.js';
import OurExpertise from './pages/OurExpertise.js';
import OurStakeholders from './pages/OurStakeholders.js';
import Notice from './pages/Notice.js';
import Events from './pages/Events.js';
import RecentDevelopments from './pages/RecentDevelopments.js';
import ContactUs from './pages/ContactUs.js';

//! import components
import Header from './components/Header.js';
import ShowProducts from './components/ShowProducts.js';
import Footer from './components/Footer.js';
import NotFound from './components/NotFound.js';
import ShowDetails from './components/ShowDetails.js';

export const UserContext = createContext();

function App() {
  // const { pathname } = useLocation();
  const { pathname } = useLocation();
  const smoothContainer = useRef(null);
  const [homeLogo, setHomeLogo] = useState([]);

  const option = {
    root: null,
    rootMargin: "0px",
    threshold: 0.2
  }

  const fireAnimation = (value) => (entrties, observer) => {
    entrties.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add(value);
        observer.unobserve(entry.target);
      }
    })
  }

  useEffect(() => {
    // here is calling some elemenst
    let handleScroll;
    const main_container = document.querySelector(".main_container");
    smoothContainer.current.scrollTo(0, 0);
    const slider = document.querySelector(".slider");
    const cmd_brand_container = document.querySelector(".cmd-brand-container");
    const navLinkdata = document.querySelectorAll(".inner_nav_link");
    const allRemoveFocusButton = document.querySelectorAll(".removeFocus");
    const navbar = document.querySelector(".navbar");
    const navbar_left = document.querySelector(".nav_left");
    const thumbnail = document.querySelector(".thumbnail");

    for (let i = 0; i < navLinkdata.length; i++) {
      let url = new URL(navLinkdata[i].href);
      if (url.pathname === pathname) {
        if (pathname === "/projects" || pathname === "/dpr" || pathname === "/photo-gallery" || pathname === "/tenders" || pathname === "/tenders-archive" || pathname === "/career" || pathname === "/apply-for-job" || pathname === "/about-cmd" || pathname === "/md-desk" || pathname === "/our-expertise" || pathname === "/stakeholders") {
          navLinkdata[i].parentElement.parentElement.parentElement.classList.add("focusAnimation");
          navLinkdata[i].parentElement.classList.add("focusAnimation");
          for (let j = 0; j < allRemoveFocusButton.length; j++) {
            if ((navLinkdata[i].parentElement !== allRemoveFocusButton[j]) && (navLinkdata[i].parentElement.parentElement.parentElement !== allRemoveFocusButton[j])) {
              allRemoveFocusButton[j].classList.remove("focusAnimation");
            }
          }
        } else {
          navLinkdata[i].parentElement.classList.add("focusAnimation");
          for (let j = 0; j < allRemoveFocusButton.length; j++) {
            if (navLinkdata[i].parentElement !== allRemoveFocusButton[j]) {
              allRemoveFocusButton[j].classList.remove("focusAnimation");
            }
          }
        }
        break;
      }
    }

    let flag = 0;
    let navFlag = 0;

    const setStyle = (val) => {
      if (val) {
        navbar.style.justifyContent = "space-between";
        navbar_left.style.display = "flex";
        if (pathname === "/") {
          thumbnail.style.bottom = "4rem";
        }
      } else {
        navbar.style.justifyContent = "center";
        navbar_left.style.display = "none";
        if (pathname === "/") {
          thumbnail.style.bottom = "2rem";
        }
      }
    }

    const setHeight2 = (increaseElement, val) => {
      if (val) {
        increaseElement.style.setProperty("height", `calc(100vh)`);
      } else {
        increaseElement.style.setProperty("height", `calc(100vh - ${cmd_brand_container !== null ? cmd_brand_container.offsetHeight : 0}px)`);
      }
    }

    const setHeight1 = (increaseElement, job) => {
      if (homeLogo[0] !== undefined) {
        if (homeLogo[0].home_logo_desktop !== "" && homeLogo[0].home_logo_mobile !== "") {
          setStyle(0);
        } else {
          setStyle(1);
        }
      }
      if (increaseElement !== null) {
        increaseElement.style.transition = "height .3s";
      }
      const handleScroll = (e) => {
        if (e.target.scrollTop > 10 && homeLogo[0] !== undefined && homeLogo[0].home_logo_desktop !== "" && homeLogo[0].home_logo_mobile !== "") {
          if (flag === 0) {
            if (job) {
              setHeight2(increaseElement, 1);
            }
            setStyle(1)
            flag = 1;
          }
        } else if (e.target.scrollTop === 0 && homeLogo[0] !== undefined && homeLogo[0].home_logo_desktop !== "" && homeLogo[0].home_logo_mobile !== "") {
          if (flag === 1) {
            if (job) {
              setHeight2(increaseElement, 0);
            }
            setStyle(0)
            flag = 0;
          }
        }

        // this is for navbar color change
        if (e.target.scrollTop > 200) {
          if (navFlag === 0) {
            navbar.style.backgroundColor = "rgba(60, 60, 60, 0.9)";
            navFlag = 1;
          }
        } else {
          if (navFlag === 1) {
            navbar.style.backgroundColor = "rgba(60, 60, 60, 0.7)";
            navFlag = 0;
          }
        }
      }
      // Remove previous event listener
      main_container.removeEventListener("scroll", handleScroll);
      main_container.addEventListener("scroll", handleScroll);

      return () => {
        main_container.removeEventListener("scroll", handleScroll);
      };

      // Add new event listener
    }

    if ("/" === pathname) {
      flag = 0;
      slider.style.setProperty("height", `calc(100vh - ${cmd_brand_container !== null ? cmd_brand_container.offsetHeight : 0}px)`);
      if (main_container.offsetWidth > 500) {
        setHeight1(slider, 1);
      }
    } else {
      flag = 0
      setHeight1(null, 0);
    }
    return () => {
      main_container.removeEventListener("scroll", handleScroll);
    };
  }, [pathname, homeLogo]);

  const fetchData = async () => {
    let data = [];
    try {
      let headerLogoData = await Axios.get("https://admin.cmdncsm.in/home-header-logo");
      data = headerLogoData.data;
      return { data };
    } catch (error) {
      return { error: true, data };
    }
  }

  useEffect(() => {
    const getAllFetchData = async () => {
      let data = await fetchData();
      if (data.error) {
        setHomeLogo([]);
      } else {
        setHomeLogo(data.data);
      }
    }
    getAllFetchData();
  }, []);


  return (
    <div className="App">
      <section className='main_container' ref={smoothContainer}>
        {homeLogo[0] !== undefined ? homeLogo[0].home_logo_desktop !== "" && homeLogo[0].home_logo_mobile !== "" ? <div className='cmd-brand-container'>
          <picture>
            <source srcSet={`https://admin.cmdncsm.in/public/home_logo/${homeLogo[0] !== undefined ? homeLogo[0].home_logo_mobile : null}`} media='(max-width: 500px)' />
            <img src={`https://admin.cmdncsm.in/public/home_logo/${homeLogo[0] !== undefined ? homeLogo[0].home_logo_desktop : null}`} alt="cmd_header_image" />
          </picture>
        </div> : <></> : <></>}
        <div className='inner-main_container'>
          <UserContext.Provider value={[option, fireAnimation]}>
            <Header />
            <Routes>
              <Route path='/' exact element={<Home />}></Route>
              <Route path='/products' element={<Product />}></Route>
              <Route path='/show-product' element={<ShowProducts />}></Route>
              <Route path='/details' element={<ShowDetails />}></Route>
              <Route path='/jobs' element={<JobForm />}></Route>
              <Route path='/events' element={<Events />}></Route>
              <Route path='/projects' element={<Project />}></Route>
              <Route path='/dpr' element={<Dpr />}></Route>
              <Route path='/photo-gallery' element={<PhotoGallery />}></Route>
              <Route path='/tenders' element={<Tender />}></Route>
              <Route path='/tenders-archive' element={<TendersArchive />}></Route>
              <Route path='/recent-developments' element={<RecentDevelopments />}></Route>
              <Route path='/notice' element={<Notice />}></Route>
              <Route path='/career' element={<Career />}></Route>
              <Route path='/apply-for-job' element={<ApplyForJob />}></Route>
              <Route path='/about-cmd' element={<AboutUs />}></Route>
              <Route path='/stakeholders' element={<OurStakeholders />}></Route>
              <Route path='/our-expertise' element={<OurExpertise />}></Route>
              <Route path='/md-desk' element={<MD />}></Route>
              <Route path='/contact-us' element={<ContactUs />}></Route>
              <Route path='*' element={<NotFound />}></Route>
            </Routes>
          </UserContext.Provider>
        </div>
        <Footer />
      </section>
    </div>
  );
}

export default App;
