// import "../style_folders/career.css"

import React, {
    useContext,
    useEffect,
    useState
} from "react";
import Axios from "axios";
import DataNotFoundMsg from "../components/DataNotFoundMsg";
import LoadingAnimation from "../components/LoadingAnimation";
import {
    Link
} from "react-router-dom";
import { UserContext } from "../App";

function Career() {
    const preDefinedFunc = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [careerBanner, setCareerBanner] = useState([]);
    const [careerJobData, setCareerJobData] = useState([]);
    document.title = "CMD | CAREER"
    useEffect(() => {
        const animationContainer = document.querySelectorAll(".show-description-heading, .career-summary, .career-job-btn-conatiner");
        const observeNewDivFunc = (element, value, thresholdValue) => {
            const observer = new IntersectionObserver(preDefinedFunc[1](value), { ...preDefinedFunc[0], threshold: thresholdValue });
            observer.observe(element);
        }
        animationContainer.forEach((item) => {
            observeNewDivFunc(item, "textTransformAnimation", .1);
        });
    });

    const fetchData = async () => {
        let data1 = [];
        let data2 = [];
        try {
            let careerBanner = await Axios.get("https://admin.cmdncsm.in/banner-data?identifier=Career");
            let careerInfo = await Axios.get("https://admin.cmdncsm.in/career-data?table_name=career_job_data");
            data1 = careerBanner.data;
            data2 = careerInfo.data;
            return { data1, data2 };
        } catch (error) {
            return { error: true, data1, data2 };
        }
    }

    useEffect(() => {
        document.querySelector(".main_container").style.overflowY = "hidden";
        const setData = async () => {
            let data = await fetchData();
            if (data.error) {
                setCareerBanner([]);
                setCareerJobData([]);
            } else {
                setCareerBanner(data.data1);
                setCareerJobData(data.data2);
            }
            setLoading(false);
            document.querySelector(".main_container").style.overflowY = "auto";
        }
        setData();
    }, []);

    return (
        <div className="career_page work-display position-relative display-margin-top-5rem">
            {loading ? <LoadingAnimation /> : null}
            <div className="career_page-header work-display-header">
                {
                    careerBanner.length !== 0 ? careerBanner[0].banner_title !== "" || careerBanner[0].image_file !== "" ? <>
                        <picture>
                            <source srcSet={`https://admin.cmdncsm.in/public/banners_data/${careerBanner[0].image_file1}`} media='(max-width: 500px)' />
                            <img src={`https://admin.cmdncsm.in/public/banners_data/${careerBanner[0].image_file}`} alt="cmd_header_image" className="tender_header-img work-display-header-img" />
                        </picture>
                        <div className="gradient gradient-more"></div>
                        <div className="tender_header-text work-display-header-text">
                            <div className="tender_header-heading work-display_header-heading letter-spacing-2px">
                                {careerBanner[0].banner_title}
                            </div>
                            {
                                careerBanner[0].banner_sub_title !== "" ? <div className="about_header-sub-heading1 work-display_header-sub-heading1 letter-spacing-1px">{careerBanner[0].banner_sub_title}</div> : null
                            }
                            {
                                careerBanner[0].alter_text !== "" ? <div className="stakeholders_header-sub-heading2 work-display_header-sub-heading2">T{careerBanner[0].alter_text}</div> : null
                            }
                        </div>
                    </> : <DataNotFoundMsg /> : null
                }
            </div>
            <div className="career-container show-description less-margin-for-mobile">
                {
                    careerJobData.length !== 0 ? careerJobData[0].title !== "" ? <div className="career-heading show-description-heading">
                        <h1 className="letter-spacing-1px">{careerJobData[0].title}</h1>
                    </div> : null : null
                }
                {
                    careerJobData.length !== 0 ? careerJobData[0].description !== "" ? <div className="career-summary">
                        <p className="text-justify" dangerouslySetInnerHTML={{__html: careerJobData[0].description}}></p>
                    </div> : null : null
                }
            </div>
            <div className="career-job-btn-conatiner">
                <Link to="/apply-for-job" className="responce-btn">APPLY FOR JOB</Link>
            </div>
        </div>

    )
}

export default React.memo(Career);